import * as React from "react";
import "../App.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
export function Home() {
  const handleSignIn = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="main">
      <Helmet>
        <title>Underwaterly - The worlds most advanced digital dive logbook</title>
        <meta
          name="description"
          content="Underwaterly is a scuba dive logging app to help scuba divers keep track of their past dives and share experiences with friends."
        />
        <meta
          name="keywords"
          content="Underwaterly, Scuba Diving, Dive Log, Dive logging, Online dive logging, Scuba dive logging, Dive logbook, Dive community, Marine life"
        />
      </Helmet>
      <div className="background-image">
        <div className="HomeHeader">
          <Header />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "75%",
            justifyContent: "center",
          }}
        >
          <div className="home--container">
            <h1 className="title--homepage">
              Take the plunge,
              <br /> dive in and capture memories
            </h1>
            <h2 className="subtitle--homepage">
              The worlds most advanced digital dive logbook. Access your dives
              at any time, anywhere.
            </h2>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Button
                variant="outlined"
                className="button button--lg button--outlined--primary"
                onClick={() =>
                  handleSignIn("https://app.underwaterly.com/login")
                }
              >
                Log In
              </Button>
              <Button
                variant="contained"
                className="button button--lg button--primary"
                onClick={() =>
                  handleSignIn("https://app.underwaterly.com/signup")
                }
              >
                Sign Up
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
