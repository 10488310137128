import React, { useState } from "react";
import Button from "@mui/material/Button";
import "../App.css";
import {
  Grid,
  Card,
  CardContent,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "../assets/icons/check_round.svg";
import CrossIcon from "../assets/icons/cross_round.svg";

//style of pricing containers
const List = styled("ul")(() => ({
  listStyle: "none",

  "li:before": {
    content: `url(${CheckIcon})`,
    position: "absolute",
    width: "25px",
    marginLeft: "-32px",
  },
  li: {
    marginBottom: "10px",
  },
}));

const ListExcluded = styled("ul")(() => ({
  listStyle: "none",
  marginTop: "-6px",

  "li:before": {
    content: `url(${CrossIcon})`,
    position: "absolute",
    width: "25px",
    marginLeft: "-32px",
  },
  li: {
    marginBottom: "10px",
  },
}));

//pricing containers
export default function Pricing() {
  const [showMonthly, setShowMonthly] = useState(false);

  return (
    <div>
      <div className="col-md-12 text-center">
        <h4 className="section-pretitle">What you pay </h4>
        <h1 className="section-title">Pricing </h1>
        <p className="section-subtitle">Learn more about our pricing</p>
      </div>
      <div className="pricing">
        <Grid container spacing={2} sx={{ maxWidth: "680px" }}>
          <Grid item xs={12} sm={6}>
            <Card className="card--pricing">
              <CardContent>
                <h2 align="center" gutterBottom>
                  Free
                </h2>
                <h3 align="center" gutterBottom>
                  0€
                  <span style={{ fontSize: "14px", color: "grey" }}>
                    /forever
                  </span>
                </h3>
                <List>
                  <li>Unlimited dive logs</li>
                  <li>Metric and imperial units</li>
                  <li>Automatic weather and location data</li>
                  <li>
                    Record general information, environment, air, equipment &
                    dive partners
                  </li>
                </List>
                <ListExcluded>
                  <li>Add custom attributes to your dives</li>
                  <li>Attach photos to each dive</li>
                  <li>Automatic dive computer import</li>
                </ListExcluded>
                <div className="buttonContainer" style={{ marginTop: "58px" }}>
                  <Button
                    variant="contained"
                    className="button button--blue"
                    onClick={() =>
                      (window.location.href =
                        "https://app.underwaterly.com/signup")
                    }
                  >
                    Sign up for free
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className="card--pricing">
              <CardContent>
                <h2 align="center" gutterBottom>
                  Premium ⭐️
                </h2>
                {showMonthly ? (
                  <>
                    <h3 align="center" gutterBottom>
                      2.99€
                      <span style={{ fontSize: "14px", color: "grey" }}>
                        /month
                      </span>
                    </h3>
                  </>
                ) : (
                  <>
                    <h3 align="center" gutterBottom>
                      9.99€
                      <span style={{ fontSize: "14px", color: "grey" }}>
                        /year
                      </span>
                    </h3>
                  </>
                )}

                <List>
                  <li>Unlimited dive logs</li>
                  <li>Metric and imperial units</li>
                  <li>Automatic weather and location data</li>
                  <li>
                    Record general information, environment, air, equipment &
                    dive partners
                  </li>
                  <li>Add custom attributes to your dives</li>
                  <li>Attach photos to each dive</li>
                  <li>Automatic dive computer import</li>
                </List>

                <FormGroup
                  style={{ alignItems: "center", marginBottom: "-20px" }}
                >
                  <FormControlLabel
                    style={{ marginLeft: "0px" }}
                    control={
                      <Switch
                        defaultChecked
                        onChange={() => setShowMonthly(!showMonthly)}
                      />
                    }
                    label="Pay yearly"
                    labelPlacement="start"
                  />
                </FormGroup>

                <div className="buttonContainer">
                  <Button
                    variant="contained"
                    className="button button--blue"
                    onClick={() =>
                      (window.location.href =
                        "https://app.underwaterly.com/signup")
                    }
                  >
                    Select Plan
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="section-white"></div>
    </div>
  );
}
