import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Header from "../components/Header";

const NotFound = () => {
  return (
    <div>
      <Header />

      <div className="error">
        <Box className="box--paperr">
          <h1>Oops...</h1>
          <h3>
            it seems that the content you wanted to visit does not exist or was
            moved.
          </h3>
          <Link to="/">
            <Button variant="contained" className="button button--primary">
              Back to Home
            </Button>
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default NotFound;
