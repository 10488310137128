import PricingTable from "../components/Pricing";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export function Pricing() {
  return (
    <div className="adkldk">
      <Helmet>
        <title>Pricing | Underwaterly</title>

        <meta
          name="description"
          content="Underwaterly is free for all users, forever. If you require more advanced features, check out our flexible and affordable premium plans."
        />
        <meta
          name="keywords"
          content="Underwaterly, Scuba Diving, Dive Log, Dive logging, Online dive logging, Scuba dive logging, Dive logbook, Dive community, Marine life, Underwaterly Pricing"
        />
      </Helmet>
      <Header />
      <div className="container" style={{ paddingBottom: "50px" }}>
        <PricingTable />
      </div>
      <Footer />
    </div>
  );
}
