import * as React from "react";
import "../App.css";
import { Link } from "react-router-dom";

import Icon from "../assets/icons/iconFooter.png";

// Contains all the footer links and the footer icon
function Footer() {
  return (
    <div className="containerFooter">
      <Link to="/ ">
        {" "}
        <img src={Icon} className="iconFooter" alt="Underwaterly" />
      </Link>
      <div className="footerNav">
        <a href="https://status.underwaterly.com">Status</a>
        <Link to="/about ">About</Link>
        <Link to="/legal">Legal</Link>
        <Link to="/privacy">Privacy</Link>
        <Link to="/imprint">Imprint</Link>
      </div>
    </div>
  );
}
export default Footer;
