import React from "react";
import Image from "../assets/images/features/1.png";
import Image3 from "../assets/images/features/4.png";
import Image4 from "../assets/images/features/7.png";
import Image5 from "../assets/images/features/9.png";
import Image6 from "../assets/images/features/10.png";
import Button from "@mui/material/Button";

import "../App.css";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export function Features() {
  return (
    <div className="main-feature">
      <Helmet>
        <title>Features | Underwaterly</title>

        <meta
          name="description"
          content="Underwaterly allows for unlimited dive logging, from every device, anywhere. Import dives from a dive computer, attach images and define custom attributes."
        />
        <meta
          name="keywords"
          content="Underwaterly, Scuba Diving, Dive Log, Dive logging, Online dive logging, Scuba dive logging, Dive logbook, Dive community, Marine life, Dive computer import, dive locations, dive custom attributes"
        />
      </Helmet>
      <Header />
      <div className="preheader">
        <h4 className="section-pretitle">What you get </h4>
        <h1 className="section-title">Features </h1>
        <p className="section-subtitle">Learn more about our features</p>
      </div>
      <div className="containerrr">
        <section className="section-feature">
          <div className="section-content">
            <div className="image-container">
              <img src={Image} alt="Underwaterly dive map on mobile screen" />
            </div>
            <div className="text-container">
              <h2>Underwaterly - have your dive sites anywhere</h2>
              <p>
                Experience{" "}
                <span class="blue-text">
                  <b>unparalleled accessibility</b>
                </span>{" "}
                with Underwaterly. Gain the freedom to{" "}
                <span class="blue-text">
                  <b>access your dives from anywhere</b>
                </span>
                , as long as you have an internet connection. Whether you're at
                home, on vacation, or exploring new dive sites, our platform
                ensures that{" "}
                <span class="blue-text">
                  <b>your dive data is readily available at your fingertips</b>
                </span>
                . Stay connected to your diving experiences no matter where your
                adventures take you.
              </p>
            </div>
          </div>
        </section>
        <section className="section-feature">
          <div className="section-content">
            <div className="text-container">
              <h2>Manage your dives even from your desktop computer</h2>
              <p>
                Our website features a fully{" "}
                <span class="blue-text">
                  <b>responsive design</b>
                </span>
                , meticulously crafted to adapt seamlessly to{" "}
                <span class="blue-text">
                  <b>every screen size</b>
                </span>
                . Whether you're browsing from a smartphone, tablet, or desktop
                computer, our website will provide an optimized experience.
                Moreover, we take pride in supporting a{" "}
                <span class="blue-text">
                  <b>wide range of browsers and operating systems</b>
                </span>
                , ensuring compatibility, and accessibility for all users.
              </p>
            </div>
            <div className="image-container">
              <img
                src={Image4}
                alt="Underwaterly dive map on large screens, such as a macbook"
              />
            </div>
          </div>
        </section>

        <section className="section-feature">
          <div className="section-content">
            <div className="image-container">
              <img
                src={Image6}
                alt="Uploading process to dives in Underwaterly"
              />
            </div>
            <div className="text-container">
              <h2>Have your dive memories all in one place</h2>
              <p>
                {" "}
                Enhance your diving experience by{" "}
                <span class="blue-text">
                  <b>easily adding images</b>
                </span>{" "}
                to your dives. With our platform, you can conveniently save and{" "}
                <span class="blue-text">
                  <b>
                    organize all your dive-related images in one central
                    location
                  </b>
                </span>
                . Say goodbye to scattered files and folders, and enjoy the
                convenience of having everything stored in a single place.
              </p>
            </div>
          </div>
        </section>
        <section className="section-feature">
          <div className="section-content">
            <div className="text-container">
              <h2>Import dives from your dive computer</h2>
              <p>
                Simplify your dive logging process with Underwaterly's{" "}
                <span class="blue-text">
                  <b>seamless integration with Suunto dive computers</b>
                </span>
                . Our platform allows you to{" "}
                <span class="blue-text">
                  <b>
                    effortlessly import your dives directly from your Suunto
                    dive computer
                  </b>
                </span>
                . Say goodbye to manual data entry and enjoy the convenience of
                automatically transferring your dive information to
                Underwaterly, making dive logging a breeze.
              </p>
            </div>
            <div className="image-container">
              <img src={Image5} alt="Dive import functionality" />
            </div>
          </div>
        </section>

        <section className="section-feature">
          <div className="section-content">
            <div className="image-container">
              <img
                src={Image3}
                alt="Imageof the account settings in Underwaterly"
              />
            </div>
            <div className="text-container">
              <h2>Manage your account from anywhere</h2>
              <p>
                At Underwaterly, we{" "}
                <span class="blue-text">
                  <b>prioritize the safety and security of your data</b>
                </span>
                . Rest assured that your information is protected with robust
                security measures. Additionally, we provide you with the
                flexibility to{" "}
                <span class="blue-text">
                  <b>export your data whenever you need it</b>
                </span>
                .{" "}
                <span class="blue-text">
                  <b>Take control of your data </b>
                </span>
                by managing your security settings according to your
                preferences. Your peace of mind is our top priority.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div
        className="section-grey"
        style={{
          textAlign: "center",
          padding: "40px 20px",
          paddingBottom: "20px",
          marginTop: "50px",
        }}
      >
        <h2>Help us to make Underwaterly better!</h2>
        <p>
          Are you looking for a feature that is currently not offered? Then
          sumbit it to our feature request board!
        </p>
        <Button
          variant="contained"
          className="button button--blue"
          onClick={() =>
            window.open(
              "https://underwaterly.canny.io/feature-request",
              "_blank"
            )
          }
        >
          {"Feature Request Board"}
        </Button>
      </div>
      <Footer />
    </div>
  );
}
