import * as React from "react";
import "../App.css";
import Button from "@mui/material/Button";
import FrequentlyAskedQuestions from "../components/Help";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import AlbumIcon from "@mui/icons-material/Album";

export function Help() {
  return (
    <div className="main">
      <Helmet>
        <title>Help | Underwaterly</title>

        <meta
          name="description"
          content="Get answers to your questions by checking the FAQ section or contact Underwaterly support."
        />
        <meta
          name="keywords"
          content="Underwaterly, Scuba Diving, Dive Log, Dive logging, Online dive logging, Scuba dive logging, Dive logbook, Dive community, Marine life, Help page, Underwaterly Server Status"
        />
      </Helmet>
      <Header />
      <FrequentlyAskedQuestions />
      <div
        className="section-grey"
        style={{
          textAlign: "center",
          padding: "40px 20px",
          paddingBottom: "20px",
          marginTop: "50px",
        }}
      >
        <h2>Can't find the answer you're looking for?</h2>
        <p>
          If you have any additional questions, please feel free to reach out to
          our friendly team at{" "}
          <a href="mailto:support@underwaterly.com">support@underwaterly.com</a>
        </p>
        <Button
          variant="contained"
          className="button button--blue"
          startIcon={<AlbumIcon />}
          onClick={() =>
            (window.location.href = "https://status.underwaterly.com/")
          }
        >
          {"System Status"}
        </Button>
      </div>
      <div
        className="section-grey"
        style={{
          textAlign: "center",
          padding: "40px 20px",
          paddingBottom: "20px",
          marginTop: "50px",
        }}
      >
        <h2>For Developers</h2>
        <p>
          Curious about our backend APIs? Contact us at{" "}
          <a href="mailto:support@underwaterly.com">hello@underwaterly.com</a>
        </p>
      </div>
      <div className="section-white" style={{ paddingBottom: "50px" }}></div>
      <Footer />
    </div>
  );
}
