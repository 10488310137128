import * as React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WebIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import SIMONZILKER from "../assets/images/simon_zilker.png";
import CHRISKRAUSSER from "../assets/images/chris.png";
import LEOBENTHAKE from "../assets/images/leo.png";
import TIMMATEJEK from "../assets/images/tim.png";

//team
function TeamGrid() {
  return (
    <section className="section-white">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center"></div>
          <div className="col-sm-6 col-md-3">
            <div className="team-item">
              <img
                src={CHRISKRAUSSER}
                className="team-img"
                alt="Christoph Kraußer"
              />
              <h3>Christoph Kraußer</h3>
              <div className="team-info">
                <p className="job">Product Owner</p>
                <p>M.Sc. Technical University Munich Information Systems</p>

                <ul className="team-icon">
                  <li>
                    <a href="https://twitter.com/" className="twitter">
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/christoph-krau%C3%9Fer-154bb81b0"
                      target="_blank"
                      rel="noreferrer"
                      className="linkedin"
                    >
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://underwaterly.com/about"
                      className="website"
                    >
                      <WebIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="team-item">
              <img
                src={LEOBENTHAKE}
                className="team-img"
                alt="Leonard Benthake"
              />
              <h3>Leonard Benthake</h3>
              <div className="team-info">
                <p className="job">CRUD Functionality</p>
                <p>M.Sc. Technical University Munich Information Systems</p>

                <ul className="team-icon">
                  <li>
                    <a href="https://twitter.com/" className="twitter">
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" className="linkedin">
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://underwaterly.com/about"
                      className="website"
                    >
                      <WebIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="team-item">
              <img src={TIMMATEJEK} className="team-img" alt="Tim Matejek" />
              <h3>Tim Matejek</h3>
              <div className="team-info">
                <p className="job">Authentication and Authorization</p>
                <p>M.Sc. Technical University Munich Information Systems</p>
                <ul className="team-icon">
                  <li>
                    <a href="https://twitter.com/" className="twitter">
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/tim-matejek-7ba416232"
                      target="_blank"
                      rel="noreferrer"
                      className="linkedin"
                    >
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://underwaterly.com/about"
                      className="website"
                    >
                      <WebIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="team-item">
              <img src={SIMONZILKER} className="team-img" alt="Simon Zilker" />
              <h3>Simon Zilker</h3>
              <div className="team-info">
                <p className="job">Data Protection</p>
                <p>M.Sc. Technical University Munich Information Systems</p>
                <ul className="team-icon">
                  <li>
                    <a href="https://twitter.com/" className="twitter">
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/simon-zilker-824b81181"
                      target="_blank"
                      rel="noreferrer"
                      className="linkedin"
                    >
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.zilker-solutions.de"
                      target="_blank"
                      rel="noreferrer"
                      className="website"
                    >
                      <WebIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamGrid;
