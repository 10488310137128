import * as React from "react";
import "../App.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export function Imprint() {
  return (
    <div className="main">
      <Helmet>
        <title>Imprint | Underwaterly</title>
      </Helmet>
      <Header />
      <div className="section-grey">
        <div className="containerImprint" style={{ padding: "0 50px" }}>
          <h1
            style={{ fontSize: "3rem", paddingTop: "10px", color: "#262626" }}
          >
            Imprint
          </h1>
          <p style={{ fontSize: "1rem", paddingTop: "10px", color: "#262626" }}>
            Underwaterly is a scuba dive logging app developed and maintained by
            Underwaterly <br />
            <br />
            <b>Underwaterly</b>
            <br />
            Boltzmannstraße 3<br />
            85748 Garching
            <br />
            Germany
            <br />
          </p>
          <div
            className="address"
            style={{ fontSize: "1rem", paddingTop: "10px", color: "#262626" }}
          >
            Email: contact@underwaterly.com
          </div>
          <div
            className="address"
            style={{ fontSize: "1rem", paddingTop: "10px", color: "#262626" }}
          >
            Phone:
          </div>
          <div
            className="address"
            style={{ fontSize: "1rem", paddingTop: "10px", color: "#262626" }}
          >
            Website: <a href="https://underwaterly.com">underwaterly.com</a>
          </div>

          <h2
            style={{ fontSize: "1rem", paddingTop: "20px", color: "#262626" }}
          >
            Executive Team
          </h2>
          <ul
            style={{ fontSize: "1rem", paddingLeft: "20px", color: "#262626" }}
          >
            <li>Christoph Kraußer</li>
            <li>Tim Matejek</li>
            <li>Leonard Benthake</li>
            <li>Simon Zilker</li>
          </ul>

          <h2
            style={{ fontSize: "1rem", paddingTop: "20px", color: "#262626" }}
          >
            Data Protection Officer
          </h2>
          <p style={{ fontSize: "1rem", color: "#262626" }}>
            We have appointed a Data Protection Officer (DPO) who is responsible
            for overseeing our data protection strategies and ensuring
            compliance with data protection laws. You can contact our DPO using
            the following details:
          </p>
          <div
            className="address"
            style={{ fontSize: "1rem", paddingTop: "10px", color: "#262626" }}
          >
            Name: Simon Zilker
          </div>
          <div
            className="address"
            style={{ fontSize: "1rem", paddingTop: "10px", color: "#262626" }}
          >
            Email: dpo@underwaterly.com
          </div>

          <h2
            style={{ fontSize: "1rem", paddingTop: "20px", color: "#262626" }}
          >
            Legal Disclaimer
          </h2>
          <p style={{ fontSize: "1rem", color: "#262626" }}>
            The information provided on this website is for general
            informational purposes only. While we strive to keep the information
            up to date and accurate, we make no representations or warranties of
            any kind, express or implied, about the completeness, accuracy,
            reliability, suitability, or availability with respect to the
            website or the information, products, services, or related graphics
            contained on the website. Any reliance you place on such information
            is therefore strictly at your own risk.
          </p>
          <p style={{ fontSize: "1rem", color: "#262626" }}>
            In no event will we be liable for any loss or damage including
            without limitation, indirect or consequential loss or damage, or any
            loss or damage whatsoever arising from loss of data or profits
            arising out of, or in connection with, the use of this website.
          </p>

          <h2
            style={{ fontSize: "1rem", paddingTop: "20px", color: "#262626" }}
          >
            Changes to this Imprint
          </h2>
          <p style={{ fontSize: "1rem", color: "#262626" }}>
            We may update this Imprint from time to time. We encourage you to
            review this page periodically for any changes. By continuing to use
            Underwaterly, you agree to the updated Imprint.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
