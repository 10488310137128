import React, { useState, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../App.css";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/icons/icon_with_text_negative.svg";
import { ReactComponent as LogoStatic } from "../assets/icons/icon_with_text_positive.svg";

function Header() {
  const navRef = useRef();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const showNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const location = useLocation();
  const currentUrl = location.pathname;

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };
  // check for url, if "/" display white icon, else colored icon
  if (currentUrl === "/") {
    return (
      <header>
        <Link to="/" aria-label="home">
          <Logo className="logo--text--negative" />
        </Link>
        <nav ref={navRef} className={isNavbarOpen ? "responsive_nav" : ""}>
          <Link to="/" onClick={closeNavbar}>
            Home
          </Link>
          <Link to="/features" onClick={closeNavbar}>
            Features
          </Link>
          <Link to="/about" onClick={closeNavbar}>
            About
          </Link>
          <Link to="/pricing" onClick={closeNavbar}>
            Pricing
          </Link>
          <Link to="/help" onClick={closeNavbar}>
            Help
          </Link>
          <button
            className="nav-btn nav-close-btn"
            aria-label="nav-close"
            onClick={showNavbar}
          >
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" aria-label="nav" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    );
  } else {
    return (
      <header className="headerStatic">
        <Link to="/" aria-label="home">
          <LogoStatic className="logo--text--negative" />
        </Link>
        <nav ref={navRef} className={isNavbarOpen ? "responsive_nav" : ""}>
          <Link to="/" onClick={closeNavbar}>
            Home
          </Link>
          <Link to="/features" onClick={closeNavbar}>
            Features
          </Link>
          <Link to="/about" onClick={closeNavbar}>
            About
          </Link>
          <Link to="/pricing" onClick={closeNavbar}>
            Pricing
          </Link>
          <Link to="/help" onClick={closeNavbar}>
            Help
          </Link>
          <button
            className="nav-btn nav-close-btn"
            aria-label="nav-close"
            onClick={showNavbar}
          >
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" aria-label="nav" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    );
  }
}

export default Header;
