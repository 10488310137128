import * as React from "react";
import "../App.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export function Privacy() {
  return (
    <div className="main">
      <Helmet>
        <title>Privacy | Underwaterly</title>
      </Helmet>
      <Header />
      <div className="section-grey">
        <div className="containerImprint" style={{ padding: "0 25px" }}>
          <h1
            style={{
              fontSize: "3rem",
              paddingLeft: "50px",
              paddingTop: "10px",
              color: "#262626",
            }}
          >
            Privacy
          </h1>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingTop: "10px",
              color: "#262626",
            }}
          ></p>

          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            We respect your privacy and are committed to protecting it through
            our compliance with this privacy policy (“Policy”). This Policy
            describes the types of information we may collect from you or that
            you may provide (“Personal Information”) on the underwaterly.com
            website (“Website” or “Service”) and any of its related products and
            services (collectively, “Services”), and our practices for
            collecting, using, maintaining, protecting, and disclosing that
            Personal Information. It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update it.
            <br />
            <br />
            This Policy is a legally binding agreement between you (“User”,
            “you” or “your”) and Underwaterly (“Underwaterly”, “we”, “us” or
            “our”). If you are entering into this agreement on behalf of a
            business or other legal entity, you represent that you have the
            authority to bind such entity to this agreement, in which case the
            terms “User”, “you” or “your” shall refer to such entity. If you do
            not have such authority, or if you do not agree with the terms of
            this agreement, you must not accept this agreement and may not
            access and use the Website and Services. By accessing and using the
            Website and Services, you acknowledge that you have read,
            understood, and agree to be bound by the terms of this Policy. This
            Policy does not apply to the practices of companies that we do not
            own or control, or to individuals that we do not employ or manage.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Automatic collection of information
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            When you open the Website, our servers automatically record
            information that your browser sends. This data may include
            information such as your device’s IP address, browser type, and
            version, operating system type and version, language preferences or
            the webpage you were visiting before you came to the Website and
            Services, pages of the Website and Services that you visit, the time
            spent on those pages, information you search for on the Website,
            access times and dates, and other statistics. <br /> <br />
            Information collected automatically is used only to identify
            potential cases of abuse and establish statistical information
            regarding the usage and traffic of the Website and Services. This
            statistical information is not otherwise aggregated in such a way
            that would identify any particular User of the system.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Information We Collect
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            When you create an account on Underwaterly, we may collect the
            following types of information:
          </p>
          <ul
            style={{ fontSize: "1rem", paddingLeft: "60px", color: "#262626" }}
          >
            <li>Your name and contact details</li>
            <li>Profile information and preferences</li>
            <li>Dive logs and dive-related data</li>
            <li>Location information (if you choose to provide it)</li>
          </ul>

          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            How We Use Your Information
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            We may use the collected information for the following purposes:
          </p>
          <ul
            style={{ fontSize: "1rem", paddingLeft: "60px", color: "#262626" }}
          >
            <li>Providing and improving the Underwaterly app</li>
            <li>Personalizing your experience and customizing content</li>
            <li>Processing payments, if applicable</li>
            <li>
              Communicating with you regarding updates or important information
            </li>
            <li>Analyzing app usage and trends to enhance our services</li>
          </ul>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Processing your Personal Information depends on how you interact
            with the Website and Services, where you are located in the world
            and if one of the following applies: (i) you have given your consent
            for one or more specific purposes; (ii) provision of information is
            necessary for the performance of an agreement with you and/or for
            any pre-contractual obligations thereof; (iii) processing is
            necessary for compliance with a legal obligation to which you are
            subject; (iv) processing is related to a task that is carried out in
            the public interest or in the exercise of official authority vested
            in us; (v) processing is necessary for the purposes of the
            legitimate interests pursued by us or by a third party. We may also
            combine or aggregate some of your Personal Information in order to
            better serve you and to improve and update our Website and Services.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Information Sharing and Security
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            We prioritize the security and confidentiality of your personal
            information. We do not sell, trade, or rent your personal
            information to third parties. However, we may share your information
            in the following circumstances:
          </p>
          <ul
            style={{ fontSize: "1rem", paddingLeft: "60px", color: "#262626" }}
          >
            <li>With your consent or as required by law</li>
            <li>
              With trusted third-party service providers who assist us in
              operating our app
            </li>
            <li>
              In response to legal requests or to protect our rights and safety
            </li>
          </ul>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            We implement reasonable security measures to protect your personal
            information from unauthorized access or disclosure.
          </p>

          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Changes to this Privacy Policy
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            We may update this Privacy Policy from time to time. We encourage
            you to review this page periodically for any changes. By continuing
            to use Underwaterly, you agree to the updated Privacy Policy.
          </p>

          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Your Rights Under GDPR
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. These rights include:
          </p>
          <ul
            style={{ fontSize: "1rem", paddingLeft: "60px", color: "#262626" }}
          >
            <li>
              The right to access, update, or delete the personal information we
              hold about you.
            </li>
            <li>The right to rectification.</li>
            <li>The right to object.</li>
            <li>The right to restrict processing.</li>
            <li>The right to data portability.</li>
            <li>The right to withdraw consent.</li>
          </ul>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            You can exercise most of these rights directly in the{" "}
            <a href="https://app.underwaterly.com/account">account settings</a>{" "}
            of your Underwaterly account. If you have any other request or need
            assistance, please contact us using the details provided below in
            the "Contact Us" section.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Cookies
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Our Website and Services use “cookies” to help personalize your
            online experience. A cookie is a text file that is placed on your
            hard disk by a web page server. Cookies cannot be used to run
            programs or deliver viruses to your computer. Cookies are uniquely
            assigned to you, and can only be read by a web server in the domain
            that issued the cookie to you. If you choose to decline cookies, you
            may not be able to fully experience the features of the Website and
            Services.
            <br />
            <br />
            We may use cookies to collect, store, and track information for
            security and personalization, to operate the Website and Services,
            and for statistical purposes. Please note that you have the ability
            to accept or decline cookies. Most web browsers automatically accept
            cookies by default, but you can modify your browser settings to
            decline cookies if you prefer.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Data analytics
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Our Website and Services may use third-party analytics tools that
            use cookies, web beacons, or other similar information-gathering
            technologies to collect standard internet activity and usage
            information. The information gathered is used to compile statistical
            reports on User activity such as how often Users visit our Website
            and Services, what pages they visit and for how long, etc. We use
            the information obtained from these analytics tools to monitor the
            performance and improve our Website and Services. We do not use
            third-party analytics tools to track or to collect any personally
            identifiable information of our Users and we will not associate any
            information gathered from the statistical reports with any
            individual User.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Stripe
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Stripe We offer the option to process the payment transaction via
            the payment service provider Stripe, ℅ Legal Process, 510,Townsend
            St., San Francisco, CA 94103 (Stripe). This is in line with our
            legitimate interest in offering an efficient and secure payment
            method (Art. 6 para. 1 lit. f DSGVO). In this context, we share the
            following data with Stripe to the extent necessary for the
            performance of the contract (Art. 6 para. 1 lit b. DSGVO).
            <br />
            <br />
            <li>Name of the cardholder</li>
            <li>E-mail address</li>
            <li>Customer number</li>
            <li>Order number</li>
            <li>Bank details</li>
            <li>Credit card data</li>
            <li>Credit card validity period</li>
            <li>Credit card verification number (CVC)</li>
            <li>Date and time of transaction</li>
            <li>Transaction amount</li>
            <li>Name of the provider</li>
            <li>Place</li>
            <br />
            Processing of the data provided under this section is not required
            by law or contract. We cannot process a payment via Stripe without
            the submission of your personal data. Stripe has a dual role in data
            processing activities as a controller and processor. As a
            controller, Stripe uses your submitted data to comply with
            regulatory obligations. This is in accordance with Stripe's
            legitimate interest (pursuant to Art. 6 (1) lit. f DSGVO) and serves
            the performance of the contract (pursuant to Art. 6 (1) lit. b
            DSGVO). We have no influence on this process. Stripe acts as an
            order processor in order to be able to complete transactions within
            the payment networks. Within the scope of the order processing
            relationship, Stripe acts exclusively according to our instructions
            and has been contractually obligated to comply with the provisions
            of data protection law within the meaning of Art. 28 DSGVO. Stripe
            has implemented compliance measures for international data
            transfers. These apply to all global activities where Stripe
            processes personal data of individuals in the EU. These measures are
            based on the EU Standard Contractual Clauses (SCCs). For more
            information about opting out and opting in with Stripe, please
            visit: https://stripe.com/privacy-center/legal. Your data will be
            stored by us until the payment processing is completed. This
            includes the period required for processing refunds, receivables
            management and fraud prevention.
          </p>
          <h2
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Contact Us
          </h2>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            If you have any questions or concerns about our Privacy Policy or
            your personal information, please contact us:
          </p>
          <p
            style={{
              fontSize: "1rem",
              paddingLeft: "50px",
              paddingRight: "50px",
              color: "#262626",
            }}
          >
            Underwaterly
            <br />
            Boltzmannstraße 3<br />
            85748 Garching
            <br />
            Germany
            <br />
            Email: dpo@underwaterly.com
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
