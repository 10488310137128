import * as React from "react";
import "../App.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export function Legal() {
  return (
    <div className="main">
      <Helmet>
        <title>Legal | Underwaterly</title>
      </Helmet>
      <Header />
      <div className="section-grey">
        <div className="containerImprint">
          <h1
            style={{
              fontSize: "3rem",
              paddingLeft: "50px",
              paddingTop: "10px",
              color: "#262626",
            }}
          >
            {" "}
            Legal
          </h1>
          <h2
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Terms and Conditions
          </h2>
          <p
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            By using Underwaterly, you agree to abide by the following terms and
            conditions:
          </p>
          <ul
            style={{ fontSize: "1rem", paddingLeft: "60px", color: "#262626" }}
          >
            <li>Use of the app is at your own risk.</li>
            <li>
              You must be of legal age or have parental consent to use the app.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account.
            </li>
            <li>
              You may not use the app for any illegal or unauthorized purpose.
            </li>
            <li>
              Underwaterly is not liable for any damages or loss resulting from
              the use of the app.
            </li>
            <li>
              We reserve the right to modify or terminate the app at any time.
            </li>
          </ul>
          <h2
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Intellectual Property
          </h2>
          <p
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            All content and materials available on Underwaterly, including but
            not limited to text, graphics, logos, button icons, images, audio
            clips, and software, are the property of Underwaterly Inc. and are
            protected by applicable intellectual property laws. Unauthorized use
            or distribution of any materials from the app is strictly
            prohibited.
          </p>
          <h2
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Disclaimer
          </h2>
          <p
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Underwaterly provides the app on an "as is" basis. We make no
            representations or warranties of any kind, expressed or implied,
            regarding the app's operation or the accuracy, completeness, or
            reliability of any information provided.
          </p>
          <h2
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Governing Law
          </h2>
          <p
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            These terms and conditions shall be governed by and construed in
            accordance with the laws of Germany. Any disputes arising under or
            in connection with these terms shall be subject to the exclusive
            jurisdiction of the courts of Germany.
          </p>
          <h2
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Contact Us
          </h2>
          <p
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            If you have any questions or concerns about our legal terms and
            conditions, please contact us:
          </p>
          <p
            style={{ fontSize: "1rem", paddingLeft: "50px", color: "#262626" }}
          >
            Underwaterly
            <br />
            Boltzmannstraße 3<br />
            85748 Garching
            <br />
            Germany
            <br />
            Email: legal@underwaterly.com
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
