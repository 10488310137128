import * as React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import TeamGrid from "./Teamgrid";
import Values from "./Values";

// Contains the team grid and the values
function Team() {
  let message = "Underwaterly originated from a university project in 2023.";
  return (
    <div className="div">
      <div className="container-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4 className="section-pretitle">About us </h4>
            <h1 className="section-title">Meet our team </h1>
            <p className="section-subtitle" style={{ padding: "0 20px" }}>
              {message}
            </p>
          </div>
        </div>

        <TeamGrid />
        <Values />
      </div>
    </div>
  );
}

export default Team;
