import * as React from "react";
import "../App.css";
import Team from "../components/Team";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export function About() {
  return (
    <div className="main">
      <Helmet>
        <title>About | Underwaterly</title>

        <meta
          name="description"
          content="Learn more about Underwaterly and the team behind it"
        />
        <meta
          name="keywords"
          content="Underwaterly, Scuba Diving, Dive Log, Dive logging, Online dive logging, Scuba dive logging, Dive logbook, Dive community, Marine lifel, Christoph Kraußer, Tim Matejek, Leonard Benthake, Simon Zilker"
        />
      </Helmet>
      <Header />
      <Team />
      <Footer />
    </div>
  );
}
