import * as React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MovingIcon from "@mui/icons-material/Moving";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FlagIcon from "@mui/icons-material/Flag";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

// Company values
function Values() {
  return (
    <section className="section-grey">
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <h1 className="how_we_work">How we work at Underwaterly</h1>
            <p className="how_we_work2">
              Our shared values keep us connected and guide us as one team.
            </p>
            <div className="containerValues">
              <div className="row">
                <div className="col-md-4">
                  <div className="stats3">
                    <h4>
                      <PeopleIcon />
                      <br />
                      Care about our team
                    </h4>
                    <p>
                      Understand what matters to our team. Give them what they
                      need to do their best work.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats3">
                    <h4>
                      <FavoriteIcon />
                      <br />
                      Be excellent to each other
                    </h4>
                    <p>
                      We rely on our peers to improve. Be open, honest, and
                      kind.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats3">
                    <h4>
                      <MovingIcon />
                      <br />
                      Pride in what we do
                    </h4>
                    <p>
                      Value quality and integrity in everything we do. At all
                      times.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats3">
                    <h4>
                      <FiberManualRecordIcon />
                      <br />
                      Do the impossible
                    </h4>
                    <p>
                      Be energized by difficult problems. Revel in unknowns. Ask
                      "Why?", but always question, "Why not?"
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats3">
                    <h4>
                      <FlagIcon />
                      <br />
                      Customer is king
                    </h4>
                    <p>Understand customers' stated and unstated needs.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats3">
                    <h4>
                      <ElectricBoltIcon />
                      <br />
                      Perfection
                    </h4>
                    <p>
                      We believe the best products come from the best attention
                      to detail.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Values;
