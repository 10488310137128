import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";

import faqContent from "../content/faq.json";
import "../App.css";

export default function Help() {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.substring(1);
  const [expanded, setExpanded] = useState(hash);

  useEffect(() => {
    setExpanded(hash);
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAnswer = (answer) => {
    return answer.map((item, index) => {
      if (item.text) {
        if (Array.isArray(item.text)) {
          // If text is an array, render each item in a separate <p> element
          return item.text.map((text, idx) => <p key={idx}>{text}</p>);
        } else {
          return <p key={index}>{item.text}</p>;
        }
      } else if (item.ul) {
        return (
          <ul key={index}>
            {item.ul.map((li, idx) => (
              <li key={idx}>{li}</li>
            ))}
          </ul>
        );
      } else if (item.img) {
        const imgSrc = require(`../assets/images/faq/${item.img}`);
        return <img key={index} src={imgSrc} alt="" className="faq--image" />;
      } else if (item.video) {
        const videoSrc = require(`../assets/videos/faq/${item.video}`);
        return (
          <video
            key={index}
            src={videoSrc}
            loop
            controls
            className="faq--video"
          >
            Your browser does not support this video.
          </video>
        );
      }
      return null;
    });
  };

  return (
    <div>
      <div className="preheader">
        <h4 className="section-pretitle">Frequently asked questions</h4>
        <h1 className="section-title">FAQ</h1>
        <p className="section-subtitle">Learn more about Underwaterly</p>
      </div>
      <div className="containerFAQ">
        <Container maxWidth="md" sx={{ mt: 10 }}>
          {faqContent.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <h2 className="section--heading">{section.section}</h2>
              {section.content.map((faq, index) => {
                const id = faq.question
                  .replace(/[^a-z\s]/gi, "")
                  .replace(/\s+/g, "-")
                  .toLowerCase();
                return (
                  <Accordion
                    className="faq--accordion--container"
                    key={index}
                    id={id}
                    expanded={expanded === id}
                    onChange={(e, isExpanded) => {
                      if (isExpanded) {
                        setExpanded(id);
                        navigate(`#${id}`);
                      } else {
                        setExpanded(null);
                        navigate(location.pathname);
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      className="accordion"
                    >
                      <h3>{faq.question}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      {renderAnswer(faq.answer)}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          ))}
        </Container>
      </div>
    </div>
  );
}
